import { MenuEntry} from '@mozartfinance/uikit'

const config: MenuEntry[] = [
  {
    label: "Page d'acceuil",
    icon: 'HomeIcon',
    href: '/home',
  },
  {
    label: 'Transaction',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Échanger',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      }, {
        label: 'Calculatrice',
        href: '/calculate',
      }
    ],
  },
  {
    label: "Compte",
    icon: 'GroupsIcon',
    href: '#'
   
  },
]


export default config
