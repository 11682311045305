import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
    color: white !important;
    background-color: #1a1f30;
   

  }
  .bodyclass{
    background-image: linear-gradient(#08081ca6, #0a0b0ef5),url(/images/image_crypto/logoback.jpg) !important;
    background-position: center !important;
    background-size: cover !important;
}
    
  }

  #root{
    background-color: #000000d9
  }

  #join-pool-button, .themeBouttonCrypto, .euvPLA, .dNnCDP{
    background-image: linear-gradient(150deg, #57048a 0%, #0047ff 78%)
  }
  
  #import-pool-link{
    color: #5e83e5!important
  }

  .jgioYy, .kNZQIA, .kNZQIA, .dXLCFm, .gxNJLM{
    box-shadow: inset 4px 0px 0px rgba(10, 149, 232, 0.9);
  }

  .kGvIMC, .gLujrM, a.jORtru, a.cJbABh{
    display: none
  }
  .bdqVjK,.dWqooP, .eRNlZq, .jiMPjx, .koUPqf, .hcmdSI,.fvIuCb, .gHIOPr {
    background: #16182D

  }
  #swap-page, .liquidityCard{
    padding: 30px;
    border: solid rgba(10, 149, 232, 0.9);
    border-radius: 40px;
    background: #16182D
  }
  .hnzGjA,.jRGlTM,.kxxKvB,.iDneoe,.text-primary{
    color: white !important
  }
  a.paheS ,a.kKguzK,.lbopBD,.fBXACb, .kKguzK{
    color: rgba(10, 149, 232, 0.9)
  }
  .fZQxKT, .ffMLJH{
    fill: rgba(10, 149, 232, 0.9)
  }
  button.bPQPFc, button.dKoNpH{
    color: rgba(10, 149, 232, 0.9) !important;
    border-color:  rgba(10, 149, 232, 0.9)
  }

  .focusInput:focus{
    border: 1px solid rgba(10, 149, 232, 0.9) !important;
  }
  [aria-label="Close the dialog"]>svg, .jgioYy svg, .kNZQIA svg{
    fill: white !important
  }
  div>a,.dtflKE,.drfFfz{
    color: white !important
  }
 


  img {
    height: auto;
    max-width: 100%;
  }
  
`

export default GlobalStyle
