import React from "react";

const Home = () => {
    return (


        <div className="container">
            <div className="card">
                <div className="row text-center items">
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="blog-content">
                            <div>
                                <h4>$12.82M</h4>
                            </div>
                            <p>Capitalisation boursière</p>
                        </div>
                    </div>
                    <div className=" col-12 col-md-6 col-lg-3">
                        <div className="blog-content">
                            <div>
                                <h4>31.50B</h4>
                            </div>
                            <p>Alimentation en circulation </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="blog-content">
                            <div>
                                <h4>82K</h4>
                            </div>
                            <p>Titulaires </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="blog-content">
                            <div>
                                <h4>$0.0005</h4>
                            </div>
                            <p>Prix</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row text-center mt-5">
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card">

                        <div className="row">
                            <div className="col-6">
                                <p>Liquidité </p>
                                <div>
                                    <h5>$1.66M</h5>
                                </div>

                            </div>
                            <div className="text-danger col-6">12%</div>

                        </div>

                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card">

                        <div className="row">
                            <div className="col-6">
                                <p>Trésorerie </p>
                                <div>
                                    <h5>$8.34M</h5>
                                </div>

                            </div>
                            <div className="text-danger col-6">0.05%</div>

                        </div>

                    </div>
                </div>


            </div>
            <div className="card mt-5">
                <div className="text-center items">
                    APY fixe  <br />
                    <h4>0.000302549%</h4>
                    Un système simple d &apos; achat-détention-gagner.
                </div>
            </div>
        </div>



    )
}

export default Home;