

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
// import Test from './test'
import Layout from './Layout';
import Lading from './Lading';

export default function App() {
    return (
        <BrowserRouter>
            <Switch >
                <Route exact path='/' component={Lading} />
                <Route path="/" component={Layout} />
            </Switch>
        </BrowserRouter>
    )
}