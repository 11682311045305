import React from "react"
import ContactLady from "./ContactLady"
import HeaderLady from "./HeaderLady";
import Price from "./Price";


const Lading = () => {
    return (
        
        <div>
            <section className="hero-section">
                <HeaderLady />

                <div className="container" style={{ marginTop: '100px' }}>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6 col-lg-9 text-center">
                            {/* Hero Content */}
                            <div className="hero-content">
                                <div className="intro text-center text-white mb-5">
                                    <span className="intro-text">
                                        EUTHENIA
                                    </span>
                                    <h2 className="h2" style={{ marginBottom: '30px', marginTop: '15px' }}><b>
                                        Réinventer la finance comme jamais auparavant
                                    </b></h2>
                                    <p className="mt-4 ">

                                        Échangez, GAGNEZ, JOUEZ et gagnez <b>un APY fixe de 0,000302549 % </b> avec le jeton AutoStaking d &apos; origine. Un protocole DeFi novateur axé sur la communauté et l &apos; innovation

                                    </p>
                                </div>
                                {/* Buttons */}
                                <div className="button-group">
                                    {/* <Link className="btn btn-primary" to="/swap">
                                    Acheter maintenant
                                </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Price />
            <ContactLady />
            </section>
        </div>
    )
}
export default Lading;