import React, { useContext, useEffect } from 'react'
import { Menu as UikitMenu } from '@mozartfinance/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import links from './config'

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const priceData = useGetPriceData()
  const cakePriceUsd = priceData ? Number(priceData.prices.PIANO) : undefined
  const profile = useGetLocalProfile()

  const localclass = Array.from(
    document.getElementsByClassName('gLujrM')
  );
  // localclass[0].removeAttribute('div')
  // console.log(localclass[0])
  // const prodBs = Array.from(
  //   document.getElementsByClassName('kKguzK')
  // );
  // const localBs = Array.from(
  //   document.getElementsByClassName('paheS')
  // );
  const prodClass = Array.from(
    document.getElementsByClassName('kGvIMC')
  );

  if (localclass.length > 0) {
    localclass[0].remove()
      // console.log('test', localclass[0])
      
  }

  if (prodClass.length > 0) {
    prodClass[0].remove()
  }
  // if(localBs.length>0){
  //   localBs[0].textContent = "Voir sur BscScan"

  // }

  
  // if(prodBs.length>0){
  //   prodBs[0].textContent = "Voir sur BscScan"
  // }
console.log("cakekkk",cakePriceUsd);
  
  return (
    <UikitMenu
      links={links}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd}


      {...props}

    />
  )
}

export default Menu
