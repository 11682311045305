import React, { useEffect, useState } from 'react';
import CardNav from 'components/CardNav';
import './style.css'
import AppBody from 'pages/AppBody';
import { CardBody } from '@mozartfinance/uikit';
import PageHeader from 'components/PageHeader';
import { AutoColumn } from 'components/Column';
import { Wrapper } from '../Pool/styleds'


const Calculator = () => {


    const [amount, setAmount] = useState(0);
    const [achat, setAchat] = useState(0);
    const [futurPrice, setFuturePrice] = useState(0);
    const [day, setDay] = useState(0);
    const [apy, setApy] = useState(283496);
    const [invest, setInvest] = useState(0);
    const [recompense, setRecompence] = useState(0);
    const [rendement, setRendement] = useState(0);
    const rebase = 0.000302549;



    function dayPrice(_amount, _day){
        console.log('oooo',_day, _amount)
        if (_day !== 0 && _amount) {
            const _recompense = _amount + (day * rebase);
            setRecompence(_recompense);
            setAmount(_amount)
        } else {
            if (_amount === '') {
                setRecompence(_amount);
               
            } else {
                setRecompence(0);
               
            }
            setAmount(0)
            
        }

    }
    
    
    function changePrice(e) {
        // console.log(day)

        const _amount = parseFloat(e.target.value);
        dayPrice(_amount, day);
       

    }
    function changeInvesti(e) {
        console.log(amount)
        setAchat(parseFloat(e.target.value))
        const _achat = parseFloat(e.target.value);
        if (amount !== 0 && _achat) {
            const _invest = amount * _achat;
            setInvest(_invest);
        } else {
            setInvest(2);
        }
    }
    function changeRendement(e) {
        // console.log(day)
        setFuturePrice(parseFloat(e.target.value))
        const _future = parseFloat(e.target.value);
        if (recompense !== 0) {
            const _rendement = _future * recompense;
            setRendement(_rendement);
        } else {
            setRendement(0);
        }
    }

    function changeDay(d) {
        console.log(d,amount)
        setDay(d);
        dayPrice(amount,d);
    }

    return (
        <>
            <CardNav activeIndex={2} />

            <AppBody>
                <Wrapper id="swap-page">
                    <PageHeader
                        title="Calculatrice"
                        description="Estimez votre retour"
                    />
                    <CardBody className='px-0' >
                        <AutoColumn gap="md">
                            <div className='card ' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <div className='text-white'>Montant EUTHENIA:</div>
                                        <div >
                                            <input placeholder="0.0" type="number" min="0" className='input-calculator' onChange={changePrice} />
                                        </div>

                                    </div>
                                    <div className=''>
                                        <div className='text-white'>Solde: 0.00</div>

                                    </div>
                                </div>

                            </div>
                            <div className='card mt-2' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <div className='text-white'>Prix d&apos;achat ($):</div>
                                        <div className=''>
                                            <input placeholder='0.0' type="number" min="0" className='input-calculator' onChange={changeInvesti} />
                                        </div>

                                    </div>
                                    <div className=''>
                                        <div className='text-white'>0.0000</div>
                                    </div>
                                </div>

                            </div>
                            <div className='card mt-2' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <div className='text-white'>Prix futur ($):</div>
                                        <div >
                                            <input placeholder="0.0" type="number" className='input-calculator' min="0" onChange={changeRendement} />
                                        </div>

                                    </div>
                                    <div className=''>
                                        <div className='text-white'>0.0000</div>
                                    </div>
                                </div>

                            </div>
                            <div className='card mt-2' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        <div className='text-white'>Combien de jours?</div>
                                        <div >
                                            <input placeholder="0.0" type="number" min="0" className='input-calculator' onChange={e => setDay(parseFloat(e.target.value))} value={day} />
                                        </div>

                                    </div>
                                    <div className='col-sm-12 col-lg-12 d-flex flex-wrap ' >

                                        <button className='mr-1 day' type='button' onClick={() => changeDay(7)} >7</button>
                                        <button className='mr-1 day' type='button' onClick={() => changeDay(15)} >15</button>
                                        <button className='mr-1 day' type='button' onClick={() => changeDay(30)} >30</button>
                                        <button className='mr-1 day' type='button' onClick={() => changeDay(60)} >60</button>
                                        <button className='mr-1 day' type='button' onClick={() => changeDay(90)} >90</button>
                                        <button className='mr-1 day' type='button' onClick={() => changeDay(180)} >180</button>
                                        <button className='day' type='button' onClick={() => changeDay(365)} >365</button>

                                    </div>
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    APY
                                </div>
                                <div>
                                    {apy} %
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    Investissement initial
                                </div>
                                <div>
                                    ${invest} USD
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    Récompenses estimées
                                </div>
                                <div>
                                    {recompense} ENIA
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    Rendement potentiel
                                </div>
                                <div>
                                    ${rendement} USD
                                </div>

                            </div>
                        </AutoColumn>


                    </CardBody>
                </Wrapper>
            </AppBody>




        </>


    )
}
export default Calculator;